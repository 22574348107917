import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import ProductImages from "../Components/Common/productImages";
import giftcardImages from "../Data/giftcardImage";

import "./styles/giftcardProduct.css";

const GiftcardProduct = () => {
    return (
        <div>
            <NavBar />

            <div className="banner">
                <img src="/GiftCards-1.png" alt="esms" />
            </div>
            
            <div className="productContent">
                <h2>Greeting Cards</h2>
                <p>We specialize in providing beautifully curated hand-made single greeting cards. Every card has an emotional meaning behind the gift giving experience. We design every card with the occasion in mind, creating beautiful sentiments and applying hand made touches and print finishes, all designed with the retail in mind.</p>
                <p>We can also design collections of boxed Christmas cards with handmade elements as well as print finishes such as glitter and pressed foils. Recognizing the diverse needs of our customers, we also specialize in designing cards for those who seek simplicity and value. Our commitment to providing value-driven options doesn’t compromise on quality or sentiment. Our products can be tailored to meet the preferences and budget considerations of every customer. We want to make sure that everyone deserves a thoughtfully designed and affordable greeting card for their special moment.</p>
                <p>Our boards are sustainably sourced, and we are happy to custom design to any occasion.</p>
            </div>

            <div className="giftcardImages">
                <ProductImages picture={giftcardImages[1].picture} alt="giftcardImages" />
                <ProductImages picture={giftcardImages[2].picture} alt="giftcardImages" />
                <ProductImages picture={giftcardImages[3].picture} alt="giftcardImages" />
                <ProductImages picture={giftcardImages[4].picture} alt="giftcardImages" />
                <ProductImages picture={giftcardImages[5].picture} alt="giftcardImages" />
                <ProductImages picture={giftcardImages[6].picture} alt="giftcardImages" />
                <ProductImages picture={giftcardImages[7].picture} alt="giftcardImages" />
                <ProductImages picture={giftcardImages[8].picture} alt="giftcardImages" />
            </div>

            <Footer />
        </div>
    );
};

export default GiftcardProduct;