const partywareImages = {
    1: {
        picture: "/partywareImages/1.png"
    },
    2: {
        picture: "/partywareImages/2.png"
    },
    3: {
        picture: "/partywareImages/3.png"
    },
    4: {
        picture: "/partywareImages/4.png"
    },
    5: {
        picture: "/partywareImages/5.png"
    },
    6: {
        picture: "/partywareImages/6.png"
    },
    7: {
        picture: "/partywareImages/7.jpg"
    },
    8: {
        picture: "/partywareImages/8.png"
    }
}

export default partywareImages;