import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import ProductImages from "../Components/Common/productImages";
import giftwrapImages from "../Data/giftwrapImage";

import "./styles/giftwrapProduct.css";

const GiftwrapProduct = () => {
    return (
        <div>
            <NavBar />

            <div className="banner">
                <img src="/GiftWrapEnsembles-1.jpg" alt="esms" />
            </div>
            
            <div className="productContent">
                <h2>Wrap and Ensembles</h2>
                <p>Our Giftwrap is meticulously crafted with exquisite designs and impeccable manufacturing. The intricate patterns and breathtaking finishes not only envelop the gift but elevate the gifting experience itself. From shimmering metallics, and vibrant glitters as well as iridescent touches and elegant embosses mixed with pressed foil finishes. Every detail is a testament to fine craftsmanship and manufacturing, leaving a lasting impression that resonates long after the gift is unwrapped.</p>
                <p>We can craft individual rolls and curate products that feature a diverse assortment of our gift products, including gift tags and bows. Each product can be individually curated for each customer depending on price point. All our papers are sustainably sourced from well-managed forests.</p>
                <p>Here is a collection of our unforgettable designs and exquisite print finishes. We pride ourselves on product innovation and continually add new print finishes and substrates.</p>
            </div>

            <div className="giftwrapImages">
                <ProductImages picture={giftwrapImages[1].picture} alt="giftwrapImage" />
                <ProductImages picture={giftwrapImages[2].picture} alt="giftwrapImage" />
                <ProductImages picture={giftwrapImages[3].picture} alt="giftwrapImage" />
                <ProductImages picture={giftwrapImages[4].picture} alt="giftwrapImage" />
                <ProductImages picture={giftwrapImages[5].picture} alt="giftwrapImage" />
                <ProductImages picture={giftwrapImages[6].picture} alt="giftwrapImage" />
                <ProductImages picture={giftwrapImages[7].picture} alt="giftwrapImage" />
                <ProductImages picture={giftwrapImages[8].picture} alt="giftwrapImage" />
                <ProductImages picture={giftwrapImages[9].picture} alt="giftwrapImage" />
                <ProductImages picture={giftwrapImages[10].picture} alt="giftwrapImage" />
            </div>

            <Footer />
        </div>
    );
};

export default GiftwrapProduct;