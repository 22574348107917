import "./styles/teammate.css";


const Teammate = (props) => {
    const { name, role, description, picture} = props;

    return (
        <div className="teammate">
            <img src={picture} alt="Teammate" />
            <div className="teammate-info">
                <h2>{name}</h2>
                <div className="role">{role}</div>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default Teammate;
