const cellogiftwrapImages = {
    1: {
        picture: "/cellogiftwrapImages/1.jpg"
    },
    2: {
        picture: "/cellogiftwrapImages/2.jpg"
    },
    3: {
        picture: "/cellogiftwrapImages/3.jpg"
    },
    4: {
        picture: "/cellogiftwrapImages/4.jpg"
    }
}

export default cellogiftwrapImages;