const sustainabilityImage = {
    1: {
        picture: "/sustainabilityImages/627735530024_AV1-1.jpg"
    },
    2: {
        picture: "/sustainabilityImages/627735530024_AV2-1.jpg"
    },
    3: {
        picture: "/sustainabilityImages/627735530024_AV6-1.jpg"
    },
    4: {
        picture: "/sustainabilityImages/627735530024_AV7-1.jpg"
    },
    5: {
        picture: "/sustainabilityImages/627735530024_AV8-1.jpg"
    },
    6: {
        picture: "/sustainabilityImages/840213713133_AV1-1.jpg"
    },
    7: {
        picture: "/sustainabilityImages/840213713164_AV1-1.jpg"
    },
    8: {
        picture: "/sustainabilityImages/840213715304_AV1-1.jpg"
    }
}

export default sustainabilityImage;