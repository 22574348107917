const giftcardImages = {
    1: {
        picture: "/giftcardImages/1.jpg"
    },
    2: {
        picture: "/giftcardImages/2.jpg"
    },
    3: {
        picture: "/giftcardImages/3.png"
    },
    4: {
        picture: "/giftcardImages/4.png"
    },
    5: {
        picture: "/giftcardImages/5.png"
    },
    6: {
        picture: "/giftcardImages/6.jpg"
    },
    7: {
        picture: "/giftcardImages/7.jpg"
    },
    8: {
        picture: "/giftcardImages/8.png"
    }
}

export default giftcardImages;