const giftboxImages = {
    1: {
        picture: "/giftboxImages/1.jpg"
    },
    2: {
        picture: "/giftboxImages/2.jpg"
    },
    3: {
        picture: "/giftboxImages/3.jpg"
    },
    4: {
        picture: "/giftboxImages/4.jpg"
    },
    5: {
        picture: "/giftboxImages/5.jpg"
    },
    6: {
        picture: "/giftboxImages/6.jpg"
    },
    7: {
        picture: "/giftboxImages/7.png"
    },
    8: {
        picture: "/giftboxImages/8.png"
    }
}

export default giftboxImages;