const INFO = {
    1: {
		name: "Belinda",
		role: "Chief of Development",
		description: "Belinda brings over 25 years experience in the consumer goods industry to the Glitter Paper Designs team. Her entrepreneurial spirit and passion for development have resulted in producing multi-million-dollar programs for retailers, manufacturing partners, licensed and private brands. Her strength lies in driving product innovation and collaboration across global teams as well as providing valuable insight into navigating the ever-changing retail landscape. As a first generation Asian American, she believes that anything is possible when you dare to make the impossible happen by simply taking that first step.",
		picture: "C_Belinda-1.png"
	},

	2: {
		name: "Lois",
		role: "Chief of Creative",
		description: "Lois brings a wealth of experience to the Glitter Paper Designs team. She has over 25 years in the paper industry as a design and trend leader. She has led Creative teams in both the UK and the USA. Her strength lies in fostering innovative and forward-thinking product designs, contributing significantly to our creative endeavors. One of her personal passions is sustainability and is a driving force for the Glitter Wrap team propelling initiatives that make a positive impact on our planet.",
		picture: "C_Lois-1.png"
	},

	3: {
		name: "Melissa",
		role: "Creative Director",
		description: "Melissa is passionate about all aspects of product design. She brings over 25 years of experience in the stationery, baby product, and children’s apparel industries as a proven leader. She delights in harmonizing her creative vision with a deep understanding of customer needs, ultimately crafting innovative and successful products.",
		picture: "C_Melissa-1.png"
	},

	4: {
		name: "Givi",
		role: "Senior Graphic Designer",
		description: "Givi is our Creative Designer at Glitter Paper, specializing in vector illustration and graphic design. She has been in the world of paper and patterns for nearly 10 years and brings a keen eye for design and fun in everything she creates. As a creative, she channels her inner child to invoke a celebration of shape and color that makes the eyes happy.",
		picture: "C_Givi-1.png"
	},

	5: {
		name: "Rodney",
		role: "VP of Sales – Value Channel",
		description: "Rodney boasts over three decades of experience in the gift packaging industry, where his passion for exceptional designs and products shines through. He treasures the connections he’s built with both colleagues and customers, placing immense value on these relationships. He is grateful to be part of the Glitter paper team and is excited for a future filled with brightness and opportunity.",
		picture: "C_Rodney-1.png"
	},

	6: {
		name: "Mindy",
		role: "Customer Support Liaison",
		description: "Mindy has extensive experience in customer support within the Walmart business, with a remarkable 14-year focus specifically on the intricacies of gift packaging. Her forte lies in fostering strong relationships as our customer support liaison with the Walmart team. She is a great addition to our dynamic team and is fueled by the prospect of contributing to our expanding business. Her enthusiasm and expertise is a valuable addition to our growth.",
		picture: "C_Mindy-1-1.png"
	},

	7: {
		name: "Andria",
		role: "Sr. Product Development",
		description: "Andria has had an incredible career steering the product development landscape for consumer goods. She has created diverse product lines specializing in paper and stationery goods along with gifting and small home décor items. Andria’s passion is innovation, and she excels in identifying market gaps and creating products that seamlessly meet our customer needs.",
		picture: "C_Andria-1.png"
	},

	8: {
		name: "Madi",
		role: "Design Manager",
		description: "With a decade of experience, Madison is a seasoned leader in the world of design. Passionate about innovation and creativity, she guides our team with a vision for excellence. From trend research to project execution, she ensures our designs are not only visually stunning but also impactful. By fostering collaboration and empowering each team member, she drives our collective success and pushes the boundaries of design.",
		picture: "C_Madi-1.png"
	},

	9: {
		name: "Pan",
		role: "Sr. Designer",
		description: "Pan is an illustrator based in Tunbridge Wells, Kent. Awarded the National Macmillan Prize for illustration, he has worked within the children’s book and greeting card industry for 20 years. He loves illustrating in many different styles whether it be traditionally or digital and has a passion for all things creative. Pan’s designs can be seen across a variety of products from around the world including home/bedding, kids clothing, greeting cards, gift wrap/bags and children’s picture books. When his head isn’t buried in his sketchbook, you can find him indulging in the graphic novels and comic books of Alan Moore or spending time with his family and his Labrador ‘Jones’ in the Kent countryside.",
		picture: "C_Pan-1.png"
	},

	10: {
		name: "Melissa",
		role: "Creative Director",
		description: "",
		picture: "C_Melissa-1.png"
	},

	11: {
		name: "Melissa",
		role: "Creative Director",
		description: "",
		picture: "C_Belinda-1.png"
	}
}

export default INFO;