import "./Styles/productIcon.css";


const ProductIcon = (props) => {
    const { src, alt, text, href} = props;

    return (
        <div className="icon-item">
            <a href={href}>
                <img src={src} alt={alt} />
                <p>{text}</p>
            </a>
        </div>
    );
};

export default ProductIcon;
