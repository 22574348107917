import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import ProductImages from "../Components/Common/productImages";
import cellogiftwrapImages from "../Data/cellogiftwrapImage";

import "./styles/cellogiftwrapProduct.css";

const CellogiftwrapProduct = () => {
    return (
        <div>
            <NavBar />

            <div className="banner">
                <img src="/Cello-1.jpg" alt="esms" />
            </div>
            
            <div className="productContent">
                <h2>Cello Gift Wrap</h2>
                <p>If you have a gift basket to wrap, or have candy for a seasonal celebration, we have the cello wrap for any occasion.</p>
                <p>Our cello wrap can be printed with simple designs to enhance any seasonal occasion.  Whether it is showcasing candy and plastic eggs for Easter baskets or adding a layer of elegance to a savory gift basket is an ideal solution.  They can be designed to fit any retail.</p>
            </div>

            <div className="cellogiftwrapImages">
                <ProductImages picture={cellogiftwrapImages[1].picture} alt="cellogiftwrapImages" />
                <ProductImages picture={cellogiftwrapImages[2].picture} alt="cellogiftwrapImages" />
                <ProductImages picture={cellogiftwrapImages[3].picture} alt="cellogiftwrapImages" />
                <ProductImages picture={cellogiftwrapImages[4].picture} alt="cellogiftwrapImages" />
            </div>

            <Footer />
        </div>
    );
};

export default CellogiftwrapProduct;