const giftbowImages = {
    1: {
        picture: "/giftbowImages/1.jpg"
    },
    2: {
        picture: "/giftbowImages/2.jpg"
    },
    3: {
        picture: "/giftbowImages/3.jpg"
    },
    4: {
        picture: "/giftbowImages/4.png"
    },
    5: {
        picture: "/giftbowImages/5.png"
    },
    6: {
        picture: "/giftbowImages/6.jpg"
    },
    7: {
        picture: "/giftbowImages/7.png"
    },
    8: {
        picture: "/giftbowImages/8.jpg"
    }
}

export default giftbowImages;