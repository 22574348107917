import  "./styles/aboutUs.css";

import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import CompanyIcon from "../Components/Landing/companyIcon";

const aboutUs = () => {
    return (
        <div>
            <body data-page="about-us">
            <NavBar />
                <div class="container">
                    <section class="about-us">
                        <div class="content1">
                            <h1 class="title">who we are</h1>
                            <h2>Established in 2019</h2>
                            <p>Glitter Paper Designs has a unique US-based design and development team with over 20+ years of industry expertise in the consumer products industry.</p>
                            <p>Our team is passionate about creating inspiring products and our designs are meticulously created to captivate the eye and elevate the art of gifting. We have unique print finishes that transform the simple act of presenting a gift into a delightful experience.</p>
                            <p>We collaborate with the most inspiring and talented product designers worldwide to ensure a distinct point of differentiation in everything we create.</p>
                            <p>Our trend forecasts, marketplace analysis, innovation, and sustainability solutions make us leaders in our seasonal categories.</p>
                            <p>Through a strategic partnership with an Asian manufacturer, we operate on a direct-to-factory model, ensuring unparalleled value and quality for all our customers.</p>
                            <p>We look forward to partnering with you to make a great product.</p>
                        </div>
                        <div class="image">
                            <img src="about-us-p1.jpg" alt="About Us"></img>
                        </div>
                    </section>
                </div>

                <div class="container">
                    <section class="our-mission">
                        <div className="content2">
                            <h2>OUR MISSON:</h2>
                            <p>Our mission is to build lasting relationships with our customers by providing quality products and unique designs at the most competitive prices.</p>
                            
                            <h2>OUR VISION:</h2>
                            <p>We aspire to become the premier destination for design and product development, a company that our customers enthusiastically choose to collaborate with.</p>

                            <h2>OUR VALUES:</h2>
                            <p>We have deep respect for our colleagues while striving to make a difference in everything we do, from product design to sustainability.  Placing our customer’s needs first as we drive value and most importantly, we like to have fun in everything we do!</p>
                        </div>
                        <div class="image">
                            <img src="about-us-p2.jpg" alt="About Us"></img>
                        </div>
                    </section>
                </div>

                <div className="about-us-banner">
                    <img src="about-us-p3.png" alt="About Us"></img>
                </div>

                <div>
                    <section className="careers">
                        <div className="content3">
                            <h2>CAREERS</h2>
                            <p>We cultivate a distinctive and adaptable work environment, in our small but growing team. We are all passionate about working together to achieve great things. Guided by our core values, we prioritize the enjoyment of every endeavor, considering it among our most significant principles in conducting business.</p>
                            <p>At Glitter Paper Designs, every team member is offered the chance to learn, evolve, and feel like they are a valued part of our workforce regardless of ability, age, cultural background ethnicity, faith, gender, gender expression, gender identity, ideology, income, national origin, race or sexual orientation and can contribute meaningfully and feel empowered to help the team make a difference and reach their full potential with dignity.</p>
                        </div>
                    </section>
                </div>

                <div>
                    <section className="open-positions">
                        <div className="content4">
                            <h2>OPEN POSITIONS</h2>
                            <p>There are no current openings. Check back soon for updates!</p>
                        </div>
                    </section>
                </div>

                <div>
                    <section className="great-products">
                        <div className="content5">
                            <h2>We like to partner with our customers to make GREAT products.</h2>
                            <p>We cherish our collaborations with retail partners, nurturing strong relationships to co-create exceptional designs and products. Our joint efforts aim for optimal sell-through, ensuring the success of our shared endeavors.</p>
                        </div>
                    </section>
                </div>

                <div className="companyIcon">
                    <CompanyIcon src="DollarGeneral_logo.png" alt="dollarGeneral" />
                    <CompanyIcon src="Walmart_logo.png" alt="walmart" />
                    <CompanyIcon src="DollarTree_logo.png" alt="dollarTree" />
                    <CompanyIcon src="Target_logo.png" alt="target" />
                    <CompanyIcon src="Dollarama_logo.png" alt="dollarama" />
                    <CompanyIcon src="HobbyLobby_logo.png" alt="hobbyLobby" />
                    <CompanyIcon src="LoBlaws_logo.png" alt="loblaws" />
                    <CompanyIcon src="FamilyDollar_logo.png" alt="familyDollar" />
                    <CompanyIcon src="Sam_logo.png" alt="samsClub" />
                    <CompanyIcon src="Michael_logo.png" alt="michales" />
                    <CompanyIcon src="WalmartCan_logo.png" alt="walmartCanada" />
                </div>

                <Footer />
            </body>
            
        </div>
    )    
}


export default aboutUs;