const giftbagImages = {
    1: {
        picture: "/giftbagImages/1.jpg"
    },
    2: {
        picture: "/giftbagImages/2.jpg"
    },
    3: {
        picture: "/giftbagImages/3.jpg"
    },
    4: {
        picture: "/giftbagImages/4.jpg"
    },
    5: {
        picture: "/giftbagImages/5.png"
    },
    6: {
        picture: "/giftbagImages/6.png"
    },
    7: {
        picture: "/giftbagImages/7.jpg"
    },
    8: {
        picture: "/giftbagImages/8.jpg"
    }
}

export default giftbagImages;