import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import ProductImages from "../Components/Common/productImages";
import giftboxImages from "../Data/giftboxImage";

import "./styles/giftbagProduct.css";

const GiftbagProduct = () => {
    return (
        <div>
            <NavBar />

            <div className="banner">
                <img src="/GiftBoxes-1.jpg" alt="esms" />
            </div>
            
            <div className="productContent">
                <h2>Gift Boxes</h2>
                <p>We offer a variety of gift boxes offering both practical and unique experiences. Foldable Boxes featuring premium heavy weight board that has been sourced sustainably from well-managed forest.</p>
                <p>Each box set includes a box and a lid. We offer a variety of sizes in white, brown kraft, or printed designs. Special finishes can also be added such as metallic foil accents or glitter.</p>
            </div>

            <div className="giftboxImages">
                <ProductImages picture={giftboxImages[1].picture} alt="giftboxImages" />
                <ProductImages picture={giftboxImages[2].picture} alt="giftboxImages" />
                <ProductImages picture={giftboxImages[3].picture} alt="giftboxImages" />
                <ProductImages picture={giftboxImages[4].picture} alt="giftboxImages" />
                <ProductImages picture={giftboxImages[5].picture} alt="giftboxImages" />
                <ProductImages picture={giftboxImages[6].picture} alt="giftboxImages" />
                <ProductImages picture={giftboxImages[7].picture} alt="giftboxImages" />
                <ProductImages picture={giftboxImages[8].picture} alt="giftboxImages" />
            </div>

            <Footer />
        </div>
    );
};

export default GiftbagProduct;