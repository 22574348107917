import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import sustainabilityImage from "../Data/sustainabilityImage";
import ProductImages from "../Components/Common/productImages";
import CompanyIcon from "../Components/Landing/companyIcon";

import "./styles/sustainability.css";

const Sustainability = () => {
    return (
        <div>
            <body data-page="sustainability" >
                <NavBar />
                <div class="container">
                    <section class="about-us">
                        <div class="content1">
                            
                        
                            <h2>Sustainability</h2>
                            <div class="eco">
                                <img src="glitterpapereco.jpg" alt="sustainability"></img>
                            </div>
                            <p>Everything we make has an impact on our people and our planet.  We are committed to minimizing our plastic and reducing its environmental impact on the environment.</p>
                            <p>We offer bamboo paper which is almost an infinitely renewable resource. Bamboo grows organically and can be harvested quickly. The plant absorbs up to four times more CO2 than some tree species.  Bamboo is also biodegradable and compostable.</p>
                            <p>In addition to Bamboo, we offer printing on recycled paper. Our commitment to fostering a circular economy extends to our paper products without print finishes, ensuring they are fully recyclable. Join us in supporting sustainable practices</p>
                            <p>If you need more information on how we make a difference, then please reach out to one of our team.</p>
                        </div>
                        <div class="image">
                            <img src="627735530024_AV6-1.jpg" alt="sustainability"></img>
                        </div>
                    </section>
                </div>

                <div className="productImageGroup">
                    <ProductImages picture={sustainabilityImage[1].picture} alt="sustainability" />
                    <ProductImages picture={sustainabilityImage[2].picture} alt="sustainability" />
                    <ProductImages picture={sustainabilityImage[3].picture} alt="sustainability" />
                    <ProductImages picture={sustainabilityImage[4].picture} alt="sustainability" />
                    <ProductImages picture={sustainabilityImage[5].picture} alt="sustainability" />
                    <ProductImages picture={sustainabilityImage[6].picture} alt="sustainability" />
                    <ProductImages picture={sustainabilityImage[7].picture} alt="sustainability" />
                    <ProductImages picture={sustainabilityImage[8].picture} alt="sustainability" />
                </div>

                <div>
                    <section className="great-products">
                        <div className="content5">
                            <h2>We like to partner with our customers to make GREAT products.</h2>
                            <p>We cherish our collaborations with retail partners, nurturing strong relationships to co-create exceptional designs and products. Our joint efforts aim for optimal sell-through, ensuring the success of our shared endeavors.</p>
                        </div>
                    </section>
                </div>

                <div className="companyIcon">
                    <CompanyIcon src="DollarGeneral_logo.png" alt="dollarGeneral" />
                    <CompanyIcon src="Walmart_logo.png" alt="walmart" />
                    <CompanyIcon src="DollarTree_logo.png" alt="dollarTree" />
                    <CompanyIcon src="Target_logo.png" alt="target" />
                    <CompanyIcon src="Dollarama_logo.png" alt="dollarama" />
                    <CompanyIcon src="HobbyLobby_logo.png" alt="hobbyLobby" />
                    <CompanyIcon src="LoBlaws_logo.png" alt="loblaws" />
                    <CompanyIcon src="FamilyDollar_logo.png" alt="familyDollar" />
                    <CompanyIcon src="Sam_logo.png" alt="samsClub" />
                    <CompanyIcon src="Michael_logo.png" alt="michales" />
                    <CompanyIcon src="WalmartCan_logo.png" alt="walmartCanada" />
                </div>

                <Footer />
            </body>
        </div>
    )
}

export default Sustainability