const giftwrapImages = {
    1: {
        picture: "/giftwrapImages/1.jpg"
    },
    2: {
        picture: "/giftwrapImages/2.jpg"
    },
    3: {
        picture: "/giftwrapImages/3.jpg"
    },
    4: {
        picture: "/giftwrapImages/4.jpg"
    },
    5: {
        picture: "/giftwrapImages/5.jpg"
    },
    6: {
        picture: "/giftwrapImages/6.png"
    },
    7: {
        picture: "/giftwrapImages/7.png"
    },
    8: {
        picture: "/giftwrapImages/8.png"
    },
    9: {
        picture: "/giftwrapImages/9.png"
    },
    10: {
        picture: "/giftwrapImages/10.png"
    }
}

export default giftwrapImages;