import NavBar from "../Components/Common/navBar";
import Footer from "../Components/Common/footer";
import Teammate from "../Components/Team/teammate";
import CompanyIcon from "../Components/Landing/companyIcon";

import teamInfo from "../Data/team";

import "./styles/team.css";

const Team = () => {
    return (
        <div>
            <body data-page="team">
            <NavBar />
            <div>
                <h2>Meet Our Team</h2>
                <p>professional & high quality</p>
            </div>
            
            <div className="teammates">
                <Teammate picture={teamInfo[1].picture} name={teamInfo[1].name} role={teamInfo[1].role} description={teamInfo[1].description} />
                <Teammate picture={teamInfo[2].picture} name={teamInfo[2].name} role={teamInfo[2].role} description={teamInfo[2].description} />
                <Teammate picture={teamInfo[3].picture} name={teamInfo[3].name} role={teamInfo[3].role} description={teamInfo[3].description} />
                <Teammate picture={teamInfo[4].picture} name={teamInfo[4].name} role={teamInfo[4].role} description={teamInfo[4].description} />
                <Teammate picture={teamInfo[5].picture} name={teamInfo[5].name} role={teamInfo[5].role} description={teamInfo[5].description} />
                <Teammate picture={teamInfo[6].picture} name={teamInfo[6].name} role={teamInfo[6].role} description={teamInfo[6].description} />
                <Teammate picture={teamInfo[7].picture} name={teamInfo[7].name} role={teamInfo[7].role} description={teamInfo[7].description} />
                <Teammate picture={teamInfo[8].picture} name={teamInfo[8].name} role={teamInfo[8].role} description={teamInfo[8].description} />
                <Teammate picture={teamInfo[9].picture} name={teamInfo[9].name} role={teamInfo[9].role} description={teamInfo[9].description} />
            </div>

            <div>
                <section className="careers">
                    <div className="content3">
                        <h2>CAREERS</h2>
                        <p>We cultivate a distinctive and adaptable work environment, in our small but growing team. We are all passionate about working together to achieve great things. Guided by our core values, we prioritize the enjoyment of every endeavor, considering it among our most significant principles in conducting business.</p>
                        <p>At Glitter Paper Designs, every team member is offered the chance to learn, evolve, and feel like they are a valued part of our workforce regardless of ability, age, cultural background ethnicity, faith, gender, gender expression, gender identity, ideology, income, national origin, race or sexual orientation and can contribute meaningfully and feel empowered to help the team make a difference and reach their full potential with dignity.</p>
                    </div>
                </section>
            </div>

            <div>
                <section className="open-positions">
                    <div className="content4">
                        <h2>OPEN POSITIONS</h2>
                        <p>There are no current openings. Check back soon for updates!</p>
                    </div>
                </section>
            </div>

            <div>
                <section className="great-products">
                    <div className="content5">
                        <h2>We like to partner with our customers to make GREAT products.</h2>
                        <p>We cherish our collaborations with retail partners, nurturing strong relationships to co-create exceptional designs and products. Our joint efforts aim for optimal sell-through, ensuring the success of our shared endeavors.</p>
                    </div>
                </section>
            </div>

            <div className="companyIcon">
                <CompanyIcon src="DollarGeneral_logo.png" alt="dollarGeneral" />
                <CompanyIcon src="Walmart_logo.png" alt="walmart" />
                <CompanyIcon src="DollarTree_logo.png" alt="dollarTree" />
                <CompanyIcon src="Target_logo.png" alt="target" />
                <CompanyIcon src="Dollarama_logo.png" alt="dollarama" />
                <CompanyIcon src="HobbyLobby_logo.png" alt="hobbyLobby" />
                <CompanyIcon src="LoBlaws_logo.png" alt="loblaws" />
                <CompanyIcon src="FamilyDollar_logo.png" alt="familyDollar" />
                <CompanyIcon src="Sam_logo.png" alt="samsClub" />
                <CompanyIcon src="Michael_logo.png" alt="michales" />
                <CompanyIcon src="WalmartCan_logo.png" alt="walmartCanada" />
            </div>
            


            <Footer />
            </body>
            
        </div>
    )
}	

export default Team;