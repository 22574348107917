import "./Styles/companyIcon.css";


const CompanyIcon = (props) => {
    const { src, alt} = props;

    return (
        <div className="company-icon">
            <img src={src} alt={alt} />
        </div>
    );
};

export default CompanyIcon;
