import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import Landing from "./Pages/landing";
import AboutUs from "./Pages/aboutUs";
import Team from "./Pages/team";
import Sustainability from "./Pages/sustainability";
import ContactUs from "./Pages/contactUs";
import GiftwrapProduct from "./Pages/giftwrapProduct";
import GiftbagProduct from "./Pages/giftbagProduct";
import GiftboxProduct from "./Pages/giftboxProduct";
import GiftcardProduct from "./Pages/giftcardProduct";
import GiftbowProduct from "./Pages/giftbowProduct";
import CellogiftwrapProduct from "./Pages/cellogiftwrapProduct";
import GifttagProduct from "./Pages/gifttagProduct";
import PartywareProduct from "./Pages/partwareProduct";
import './App.css';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/team" element={<Team />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/product/wrap-and-ensembles" element={<GiftwrapProduct />} />
          <Route path="/product/gift-bags" element={<GiftbagProduct />} />
          <Route path="/product/gift-boxes" element={<GiftboxProduct />} />
          <Route path="/product/greeting-cards" element={<GiftcardProduct />} />
          <Route path="/product/gift-bows" element={<GiftbowProduct />} />
          <Route path="/product/cello-gift-wrap" element={<CellogiftwrapProduct />} />
          <Route path="/product/gift-tag" element={<GifttagProduct />} />
          <Route path="/product/partyware" element={<PartywareProduct />} />
        </Routes>
      </Router>
		</div>
  );
}

export default App;
