const gifttagImages = {
    1: {
        picture: "/gifttagImages/1.png"
    },
    2: {
        picture: "/gifttagImages/2.png"
    },
    3: {
        picture: "/gifttagImages/3.jpg"
    },
    4: {
        picture: "/gifttagImages/4.png"
    },
    5: {
        picture: "/gifttagImages/5.png"
    },
    6: {
        picture: "/gifttagImages/6.png"
    },
    7: {
        picture: "/gifttagImages/7.jpg"
    },
    8: {
        picture: "/gifttagImages/8.jpg"
    }
}

export default gifttagImages;