import "./Styles/productImages.css";

const ProductImages = (props) => {
    const {picture, alt} = props;

    return (
        <div className="productImage">
            <img src={picture} alt={alt} />
        </div>
    );
};

export default ProductImages;